/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_1eac60';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/dfa8b99978df7bbc-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_1eac60';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/42d52f46a26971a3-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_1eac60';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a6d330d7873e7320-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_1eac60';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a5b77b63ef20339c-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_1eac60';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d117eea74e01de14-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_1eac60';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/eb52b768f62eeeb4-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_1eac60';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/627622453ef56b0d-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Fallback_1eac60';src: local("Arial");ascent-override: 92.67%;descent-override: 24.39%;line-gap-override: 0.00%;size-adjust: 100.11%
}.__className_1eac60 {font-family: '__Roboto_1eac60', '__Roboto_Fallback_1eac60';font-weight: 500;font-style: normal
}.__variable_1eac60 {--font-roboto: '__Roboto_1eac60', '__Roboto_Fallback_1eac60'
}

